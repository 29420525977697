/* LoadingOverlay.css */
.loadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent white */
    z-index: 1000; /* Ensure it covers everything else in the component */
  }
  