.profile-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    margin: 0px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    border-radius: 8px;
    background-color: #fff;
    cursor: pointer;
    transition: box-shadow 0.2s ease, transform 0.2s ease; /* Smooth transition for shadow and transform */
  }

  .profile-card:active {
    box-shadow: 0 1px 2px rgba(0,0,0,0.1); /* Reduce shadow to give a "pressed" effect */
    transform: translateY(2px); /* Slightly move the card down */
  }
  
  .profile-photo-container {
    
    width: 120px;
    height: 120px;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 15px;
  }
  
  .profile-photo {
    width: 100%;
    height: auto;
  }
  
  .profile-info {
    text-align: center;
    height: 85px;
    overflow: hidden; /* Hide overflow content */
    
   
  }
  
  .profile-name {
    margin: 0;
    color: #333;
  }
  
  .profile-keywords {
    color: #666;
    font-size: 14px;
  }
  
  .profile-actions {
    margin-top: 15px;
  }
  
  .more-info-btn {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .more-info-btn:hover {
    background-color: #0056b3;
  }
  
  /*Profile overlay*/

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .overlay-content {
    display: flex;
    background-color: white;
    padding: 10px;
    padding-top: 20px;
    border-radius: 5px;
    align-items:flex-start;
    gap: 20px;
  }
  .overlay-photo-container {
    max-width: 150px;
    width: 100%; /* Full width of the parent */
    aspect-ratio: 3 / 4; /* Ensures the width and height are always equal, maintaining a circle */
    border-radius: 50%;
    background-color: lightgrey;
    position: relative;
    overflow: hidden;
  }

  /* .overlay-photo-container {
    width: 100%; 
    padding-top: 133.33%; 
    border-radius: 50%;
    background-color: lightgrey;
    position: relative;
    overflow: hidden;
  } */

  /* .overlay-photo-container {
    width: 150px;
    height: 200px;
    border-radius: 50%;
    background-color: lightgrey;
    position: relative;
    overflow: hidden;
  } */

  /* Responsive adjustments */
  @media (max-width: 768px) {
    .overlay-photo-container {
      max-width: 150px; /* Smaller max size for smaller devices */
      min-width: 75px;
    }
  }

  .close-button {
    position: absolute;
    top: 10px; /* Adjust as needed */
    right: 10px; /* Adjust as needed */
    width: 30px; /* Size of the button */
    height: 30px; /* Size of the button */
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
  }
  
  .close-button::before,
  .close-button::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px; /* Length of the line */
    height: 2px; /* Thickness of the line */
    background-color: #333; /* Color of the line */
  }
  
  .close-button::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  
  .close-button::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  
  .close-button:hover::before,
  .close-button:hover::after {
    background-color: #000; /* Darker color on hover for better UX */
  }