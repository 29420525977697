.App {
  text-align: center;
}

.header-font-white {
  color: white;
}

#navbar {
  position: sticky;
  zIndex: 1003;
  backgroundColor: #16191f;
}

*This CSS hide the signUp tab
.amplify-tabs {
  display: none;
}


h1 { color: #455D58;
  font-family: 'Recoleta Regular', cursive; /*'Rouge Script', cursive */
  /* font-size: 90px; 5.9vw */
  font-size: 45px;
  font-weight: normal; 
  line-height: 48px; 
  margin: 10px 10px 10px 10px; 
  text-align: center; 
}



/* .inlineView {
  position: relative;
  background: no-repeat url(crossroad.jpg);
  background-size: cover;
  background-position: center;
  height: 80vh;
  width: 100%;
  margin-bottom: 60px;
  text-align: center;
  display: block;
}

.inlineView > img {
  vertical-align: top;
  opacity: 0;  
  
  height: 80vh;
}

.inlineView > div {

  display: inline-block;
  vertical-align: bottom;

} */


/* #foracademic {
  position: relative;
  background: no-repeat url(graduation.jpg);
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;

  text-align: center;
}

#foracademic > img {
  vertical-align: top;
  opacity: 0;  
  
  height: 100%;
}

#foracademic > div {

  display: inline-block;
  vertical-align: bottom;
  margin-bottom: 10%;
  text-align: center;
} */

/* #forprofess {
  position: relative;
  background: no-repeat url(professionals.jpg);
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;

  text-align: center;
}

#forprofess > img {
  vertical-align: top;
  opacity: 0;  
  
  height: 100%;
}

#forprofess > div {

  display: inline-block;
  vertical-align: bottom;
  margin-bottom: 10%;
  text-align: center;

} */

/* Newsletter page styling */
#comingsoon {
  position: relative;
  background: no-repeat url(forest.jpg);
  background-size: cover;
  background-position: center;
  height: 80vh;
  width: 100%;
  margin-top: 10px;
  text-align: center;
  /* display: block; */
  display: flex;         /* Changed to flex */
  align-items: center;   /* Centers vertically */
  justify-content: center; /* Centers horizontally */
  margin-bottom: 1rem;
}

#comingsoon > img {
  vertical-align: top;
  opacity: 0;  /* make it transparent */

  height: 80vh;
}

#comingsoon > div {

  display: block;
  text-align: center;
  position:absolute;
  margin: auto;
  padding: 1rem;
  /* bottom: 30%;
  left: 28.5%; */
}  

@media only screen and (max-width: 600px) {
  #comingsoon > div {

    display: block;
    text-align: center;
    position:absolute;
    margin: auto;
    padding: 1rem;
    bottom: 30%;
  } 
}


@media (min-width: 601px) and (max-width: 800px) {
  #comingsoon > div {
    display: block;
    text-align: center;
    margin-top: 20%;
    width: 100%; /* Width set to 80% for screens within this range */
  }
}

/* Styles for screens larger than 800px */
@media (min-width: 801px) {
  #comingsoon > div {
    display: block;
    text-align: center;
    margin-top: 20%;
    width: 80%; /* Width set to 60% for larger screens */
  }
}


/* HR profile photo styling */
.photo-container {
  width: 150px;
  height: 200px;
  border-radius: 50%;
  background-color: lightgrey;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.profile-photo, .upload-label {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  margin: 0;
}

.photo-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 50%;
}

.photo-container:hover .photo-overlay {
  opacity: 1;
}

.icon-placeholder {
  font-size: 120px; /* Adjust size as needed */
  color: #ccc; /* Icon color */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}



/*................................*/

/*Home page styling*/


.list-item {
  text-align: left;
  font-family: "Georgia", serif;
  font-size: calc(1rem + 1vw);
  font-weight: 400;
  margin-bottom: 10px;
  color: black;
}

.text {
  font-family: "Georgia", serif;
  font-size: calc(1rem + 1vw);
  font-weight: 400;
}


.heading2 {
  font-weight: 200;
  color: white;
}

/* vertically align text in a div */
.va-m-container { 
  height: 100%;
  position: relative;
}

.va-c-container {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}


/*Login page styling*/
#TestsDiv {
  display:none;
 }

#addmoredegs {
  margin: 0;
  position:absolute;
  bottom:0;
  left:0;
}

/* This adjust the SignIn font size */
:root{
--amplify-components-fieldcontrol-small-font-size: 1.5rem;
--amplify-components-button-font-size: 1.5rem;
--amplify-components-fieldcontrol-font-size: 1.5rem;
--amplify-components-tabs-item-font-size: 1.5rem;
}

.welcomeview{
  position:relative
}


#signin {
  padding: 7px 20px !important;
  border-radius: 2px;
  margin: 20px 20px 0px 20px;
  background-color:rgba(255,255,255,0);
  font-size:x-large;
  font-weight: 300;
  color: #ffff;
  border: 2px solid #ffff;
  transition: 0.6s;
  font-family: 'Scto-Grotesk', sans-serif;

}

#signin:hover {
  background-color: #ffff;
  cursor: pointer;
  color: black;
}

#signin.green-button:hover a{
  color: #1ec279;
}

/* Adjust the font size on medium screens (e.g., tablets) */
@media (max-width: 768px) {
  #signin {
    font-size: x-large; /* Slightly larger font size for better readability on smaller screens */
  }
}

/* Adjust the font size on small screens (e.g., mobile phones) */
@media (max-width: 480px) {
  #signin {
    font-size: 3vw; /* Even larger font size to ensure readability on very small screens */
  }
}

#aboutus {
  padding: 7px 20px !important;
  border-radius: 2px;
  margin: 20px 0px 0px 20px;
  background-color:rgba(255,255,255,0);
  font-size:x-large;
  font-weight: 300;
  color: #ffff;
  border: 2px solid #ffff;
  transition: 0.6s;
  font-family: 'Scto-Grotesk', sans-serif;
 
  left: 20px; /* Add this line */
  right:auto;
}

#aboutus:hover {
  background-color: #ffff;
  cursor: pointer;
  color: black;
}

#aboutus.green-button:hover a{
  color: #1ec279;
}

@media (max-width: 768px) {
  #aboutus {
    font-size: x-large; /* Slightly larger font size for better readability on smaller screens */
  }
}

/* Adjust the font size on small screens (e.g., mobile phones) */
@media (max-width: 480px) {
  #aboutus {
    font-size: 3vw; /* Even larger font size to ensure readability on very small screens */
    background-color: #004000; 
  }
}

#logotextbutton {
  padding: 7px 20px !important;
  border-radius: 2px;
  margin: 20px 20px 10px 50px;
  background-color:rgba(255,255,255,0);
  font-size:x-large;
  font-weight: 300;
  color: #ffff;
  transition: 0.6s;
  font-family: 'Scto-Grotesk', sans-serif;
}

#logotextbutton:hover {
  background-color: #ffff;
  cursor: pointer;
  color: black;
}

#logotextbutton.green-button:hover a{
  color: #1ec279;
}

button {
  font-size: 2rem;
  font-family: inherit;
  border: none;
  border-radius: 8px;
  padding: 0.5rem 0.75rem;
  box-shadow: 0 0px 1px hsla(0, 0%, 0%, 0.2), 0 1px 2px hsla(0, 0%, 0%, 0.2);
  background-color: white;
  line-height: 1.5;
  margin: 0;
  position:absolute;
  top:0;
  right:0;
  z-index: 2;
}
button {
  color: #3992ff;
  font-weight: 800;
  font-size: 3rem;
}

textarea:hover,
input:hover,
button:hover {
  box-shadow: 0 0px 1px hsla(0, 0%, 0%, 0.6), 0 1px 2px hsla(0, 0%, 0%, 0.2);
}

button:active {
  box-shadow: 0 0px 1px hsla(0, 0%, 0%, 0.4);
  transform: translateY(1px);
}

.amplify-expander {
  white-space: pre-line;
}

#resJobs {
  white-space: pre-line;
}
/* #contact h1 form button {
  box-shadow: none;
  font-size: 3rem;
  font-weight: 400;
  padding: 0;
}
#contact h1 form button[value="true"] {
  color: #a4a4a4;
}
#contact h1 form button[value="true"]:hover,
#contact h1 form button[value="false"] {
  color: #eeb004;
} */


.applyButton {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.applyButton:hover {
  background-color: #0056b3;
}

.greyed-out {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #ccc; /* Adjust the color for the greyed-out state */
  color: #666; /* Adjust the color for the greyed-out state */
  border: none;
  border-radius: 5px;
  cursor: not-allowed;
  opacity: 0.5;
}

/* Optional: Add a different color for the greyed-out state on hover */
.greyed-out:hover {
  background-color: #ccc;
}

/* @keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.bounce {
  animation-name: bounce;
  animation-duration: 1s;
  animation-timing-function: ease;
} */
