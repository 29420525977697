.application-container {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .application-form {
    display: flex;
    flex-direction: column;
    width: 300px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    font-weight: bold;
  }
  
  input[type="text"],
  select,
  input[type="file"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  button {
    padding: 8px 12px;
    margin-right: 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
/* ... existing styles */

.file-upload {
    display: flex;
    align-items: center;
  }
  
  .file-upload input[type="file"] {
    flex: 1;
    margin-right: 10px;
  }
  
  .button-group {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
  
  